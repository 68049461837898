import React, { Component } from "react";
import "../../styles/CollegeBoardDoc.css";
 
class AboutUnity extends Component {
  render() {
    return (
      <div className="myclass" style={{textAlign: "center"}}>
        <h1>ABOUT UNITY</h1>
        <img src="https://2kxk9g.bl.files.1drv.com/y4mnivuuBW_S19-No0jnWn5KztGlxdD-Dh3j1ZFY-Sr_IxjLpSiKa0rEChxQVtc3YJoXoMcMQKFU6xH1QK28eOGzshzEq62MWxXn8ufSQX3_SsaCtD25qaEgke1vNuIBhIhpbsp5fmghwx27Z-RDqr_rP8v3BU8qOCwUGeJv5KiF5IGTaRvT6LdFgZkWq1Ye3Ain3moPTg6Q4-oFGf1mYF6Hg?width=861&height=565&cropmode=none" 
        width="861" height="565" alt="aboutUnity" />
      </div>
    );
  }
}
 
export default AboutUnity;