import React, { Component } from "react";
import "../../styles/CollegeBoardDoc.css";
 
class GamesUnity extends Component {
  render() {
    return (
      <div className="myclass">
        <h1>GAMES UNITY</h1>
      </div>
    );
  }
}
 
export default GamesUnity;