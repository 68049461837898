import React, { Component } from "react";
import "../../styles/CollegeBoardDoc.css";
 
class MyGameUnity extends Component {
  render() {
    return (
      <div className="myclass">
        <h1>ABOUT MY UNITY</h1>
      </div>
    );
  }
}
 
export default MyGameUnity;